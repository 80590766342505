<template>
  <div id="app">
    <editor
      id="editor_id"
      height="400px"
      :content.sync="editorText"
      :afterChange="afterChange()"
      :loadStyleMode="false"
      @on-content-change="onContentChange"
    ></editor>
    <p class="iot-preview-p" v-html="editorTextCopy"></p>
  </div>
</template>
 
<script>
import editor from "../components/kindeditor.vue";

export default {
  name: "EditorDoc",
  components: {
    editor,
  },
  data() {
    return {
      editorText: "", // 双向同步的变量
      editorTextCopy: "", // content-change 事件回掉改变的对象
    };
  },
  methods: {
    onContentChange(val) {
      this.editorTextCopy = val;
    },
    afterChange() {},
  },
};
</script>
<style scoped>
#app {
  width: 100%;
  padding: 30px;
}
#app >>> .kindeditor,
.iot-preview-p {
  display: inline-block;
  width: 50%;
}
.iot-preview-p {
  float: right;
  height: 100%;
  overflow-y: scroll;
}
</style>